import React from 'react';
import {Seo} from 'components';
import Layout from 'layout';

// import page style
import './404.scss';

const NotFoundPage = () => (
  <Layout indication="patient" className="patient-404">
    <Seo pageTitle="Patient page not found!" />
    <h1>NOT FOUND</h1>
    <p>Sorry, this page doesn't exist!</p>
  </Layout>
);

export default NotFoundPage;
